/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import tw, { css, theme } from "twin.macro";
import Img from "gatsby-image";
import { CacheContext } from "~context/CacheContext.jsx";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";
import Go from "~components/Go.jsx";

const Diamond = ({ diamond, index }) => {
  const { diamondImages } = useContext(CacheContext);
  const { isDesktop } = useContext(DocumentContext);

  //

  const diamondIconStyle = [tw`w-1/3`];

  const diamondIcons = {
    Round: {
      svg: <Icon.Round styles={diamondIconStyle} selected />
    },
    Oval: {
      svg: <Icon.Oval styles={diamondIconStyle} selected />
    },
    Cushion: {
      svg: <Icon.Cushion styles={diamondIconStyle} selected />
    },
    Emerald: {
      svg: <Icon.Emerald styles={diamondIconStyle} selected />
    },
    Asscher: {
      svg: <Icon.Asscher styles={diamondIconStyle} selected />
    },
    Pear: {
      svg: <Icon.Pear styles={diamondIconStyle} selected />
    },
    "Rose Cut": {
      svg: <Icon.RoseCut styles={diamondIconStyle} selected />
    },
    Marquise: {
      svg: <Icon.Marquise styles={diamondIconStyle} selected />
    },
    Trillion: {
      svg: <Icon.Trillion styles={diamondIconStyle} selected />
    },
    "Old European": {
      svg: <Icon.OldEuropean styles={diamondIconStyle} selected />
    },
    "Old Mine": {
      svg: <Icon.OldMine styles={diamondIconStyle} selected />
    },
    Radiant: {
      svg: <Icon.Radiant styles={diamondIconStyle} selected />
    },
    Princess: {
      svg: <Icon.Princess styles={diamondIconStyle} selected />
    }
  };

  let image;

  if (diamondImages?.[0]) {
    diamondImages.forEach((diamondImage) => {
      if (image) {
        return;
      }

      if (diamondImage?.title?.toLowerCase() === diamond?.Shape.toLowerCase()) {
        ({ image } = diamondImage);
      }
    });
  }

  //

  return (
    <div
      css={[
        css`
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          transform: translate3d(0, 0, 0);
          padding-bottom: 100%;
          backface-visibility: hidden;
          perspective: 1000;
          transform-style: preserve-3d;
        `,
        tw`w-full relative overflow-hidden`
      ]}
    >
      <div
        css={[
          css`
            height: 1px;
          `,
          tw`w-full absolute right-0 bottom-0 left-0 z-10 bg-koamaru`
        ]}
      />
      <div
        css={[
          css`
            width: 1px;
          `,
          tw`h-full absolute top-0 right-0 bottom-0 z-10 bg-koamaru`
        ]}
      />

      <article
        css={[
          css`
            &:hover {
              .diamond-grid__item__arrow {
                opacity: 1;
                transform: translate3d(0, 0, 0);
              }

              .gatsby-image-wrapper {
                transform: scale(1.025);
              }
            }

            .diamond-grid__item__arrow {
              transition: opacity 0.3s ${A.EASING_CUBIC},
                transform 0.3s ${A.EASING_CUBIC};

              opacity: 0;
              transform: translate3d(-0.5rem, 0, 0);
            }

            .gatsby-image-wrapper {
              transition: transform 0.3s ${A.EASING_CUBIC};
            }
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center p-4 text-koamaru`
        ]}
      >
        <figure
          className="diamond-grid__item__image"
          css={[
            css`
              ${A.Keyframes(
                `appearRight`,
                `0.75s ${A.EASING_CUBIC} forwards`,
                `${index * 25 < 500 ? index * 25 : 500}ms`
              )}
            `,
            tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center`
          ]}
        >
          {(image?.asset?.fluid && (
            <Img
              css={[tw`w-1/2 relative block`]}
              fluid={image.asset.fluid}
              alt={diamond.id}
            />
          )) || (
            <>
              {diamondIcons?.[diamond?.Shape]?.svg ? (
                diamondIcons[diamond.Shape].svg
              ) : (
                <></>
              )}
            </>
          )}
        </figure>

        <Go
          to={`https://frankdarling.com/diamonds/diamond-${diamond.Diamond_ID}`}
          newTab
          styles={[
            css`
              ${A.Keyframes(
                `appearRight`,
                `0.75s ${A.EASING_CUBIC} forwards`,
                `${index * 25 < 500 ? 100 + index * 25 : 500}ms`
              )}
            `,
            tw`w-full h-full relative z-10 flex flex-col justify-between`
          ]}
        >
          <header>
            <T.Heading font="b1" level="3">
              {diamond.Carat}ct {diamond.Shape} {diamond.Cut_Grade}
            </T.Heading>
            <T.Heading font="b1" level="3">
              {diamond.Color_Name} {diamond.Clarity}
              {` `}
              {diamond.Type}
              {diamond?.Type !== `Moissanite` ? ` Diamond` : ``}
            </T.Heading>
          </header>

          <div css={[tw`flex items-center`]}>
            <T.Heading font="b1" level="4" serif>
              $
            </T.Heading>

            <T.Heading font={isDesktop() ? `4` : `2`} level="3">
              {diamond.Cost}
            </T.Heading>

            <T.Caption styles={[tw`block -mt-1`]}>USD</T.Caption>
          </div>

          <div
            className="diamond-grid__item__arrow"
            tw="h-full absolute top-0 right-0 bottom-0 flex items-center justify-center"
          >
            <Icon.Arrow color="#1c1794" styles={[tw`w-3`]} />
          </div>
        </Go>
      </article>
    </div>
  );
};

export default Diamond;
