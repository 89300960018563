/* eslint-disable react/prop-types */

import React from "react";
import tw, { css } from "twin.macro";
import * as T from "~components/styles/Typography.jsx";

const Explainer = ({ explainer }) => (
  <div
    css={[
      css`
        padding-bottom: 100%;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
      `,
      tw`w-full relative`
    ]}
  >
    <div
      css={[
        css`
          height: 1px;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        `,
        tw`w-full absolute right-0 bottom-0 left-0 z-10 bg-koamaru`
      ]}
    />
    <div
      css={[
        css`
          width: 1px;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        `,
        tw`h-full absolute top-0 right-0 bottom-0 z-10 bg-koamaru`
      ]}
    />

    <article
      css={[
        css`
          -webkit-mask-image: -webkit-radial-gradient(white, black);
        `,
        tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex flex-col items-center justify-center p-4 text-koamaru text-center`
      ]}
    >
      <T.Heading font="2" level="3" serif styles={[tw`mb-8`]}>
        {explainer.heading}
      </T.Heading>

      <T.Heading font="5" level="4" serif>
        {explainer.body}
      </T.Heading>
    </article>
  </div>
);

export default Explainer;
